
:root{
    --colorRight: #3aa394;
    --colorPlace: #d3ad69;
    --colorWrong: #312a2c;
    --colorWrongFG: #504a4b;
}



.Celula{
    border: 4px solid rgb(76, 67, 71);
    width: 40px;
    height: 40px;
    outline: 0;
    border-radius: 5px;
    background-color: transparent;
    font-size: 30px;   
    color: aliceblue;
    text-align: center;
    caret-color: transparent;
    margin: 2px;    
    font-weight: 700;
}

.Celula:focus{
    border-bottom: 7px solid rgb(76, 67, 71);
    height: 37px;
}

.Desabilitado{
    background-color: rgb(76, 67, 71);
}

.Certo{
    background-color: var(--colorRight);
    border-color: var(--colorRight);
}


.Errado{
    background-color: var(--colorWrong);
    border-color: var(--colorWrong);
}

.PosicaoErrada{
    background-color: var(--colorPlace);
    border-color: var(--colorPlace);
}